import { Article, ArticleInfoType, ArticleReference, DataPool, OrderOptions, RepairTimeProvider, OE, BonusSystem } from "@tm/models"
import {
    BasketErpInformationRequestItem,
    BasketErpInformationResponseItem,
    CalculatedOrderItem,
    GetErpInformationBasketRequest,
    GetErpInformationBasketResponse,
    PartItem,
    WorkItem,
    CalculatedPart,
    WorkResponse,
    OrderGroup,
    SelectedOrderOptions,
    CalculatedWork,
    CalculateBonusItemResponse,
    CalculateWorkTaskBasketResponse,
} from "./data/model"

export enum PartListItemActions {
    Note = 1,
    Details = 2,
}

export type OrderOptionsGroup = {
    distributorId?: number
    warehouseId?: string
    orderOptions?: OrderOptions
    isLoading?: boolean
    isFetching?: boolean
    hasErrors?: boolean
    errorText?: string
    disableOrder?: boolean
}

export type BasketOrderGroup = {
    basketParts: BasketPart[]
    bonusPointTotalsInOrder?: Record<number, number>
    completeDeliveryTourStart?: Date
    showAdditionalCostsConfirmation?: boolean
    hasOeParts: boolean
    hasPartsBeingUpdated?: boolean
    hasPartsExcludedFromOrder: boolean
    hasPartsIncludedInOrder: boolean
    hasPartsLoadingErpInfo?: boolean
    hasIncludedPartsOrderable?: boolean
    orderGroup: OrderGroup
    orderOptionsGroup?: OrderOptionsGroup
    orderOptionsLoading?: boolean
    selectedOrderOptions?: SelectedOrderOptions
}

export type BasketErp = { basketErpInformation: BasketErpInfo[]; basketErpInfosIndicator: BasketErpIndicator }

export type BasketPart = {
    bonusSystemsWithoutPoints?: BonusSystem[]
    bonusPoints?: CalculateBonusItemResponse
    calculatedOrderItem?: CalculatedOrderItem
    calculatedPart?: CalculatedPart
    erpInfoRequest?: BasketErpInformationRequestItem
    erpInfoResponse?: BasketErpInformationResponseItem
    linkedItems?: BasketPart[]
    partItem: PartItem
    article?: Article
    references?: ArticleReference[]
    repairTimeProviders?: RepairTimeProvider[]
    selectedOrderOptions?: SelectedOrderOptions
    states: BasketPartStates
}

// used only in the frontend
type BasketPartStates = {
    isBeingUpdated?: boolean
    isEditorOpen?: boolean
    isErpInfoBeingLoaded?: boolean
    isNoteExpanded?: boolean
    isOrderOptionsBeingUpdated?: boolean
    isOrderOptionsCollapsed?: boolean
    isSelected?: boolean
}

export type BasketErpInfo = {
    erpInfos?: GetErpInformationBasketResponse
    erpInfosLoading: boolean
    erpInfosReloading: boolean
    dataUpdatedAt: number
    request: GetErpInformationBasketRequest
    erpInfosWithError: boolean
}

export type BasketErpInfoGroup = {
    distributorId?: number
    parts: BasketErpInformationRequestItem[]
}

export type BasketErpIndicator = {
    hasErrors: boolean
    hasOrderOptions: boolean
    isLoaded: boolean
    isLoading: boolean
    showAlternatives: boolean
    showBonusInformation: boolean
    showGraduatedPrices: boolean
    showAdditionalCostsConfirmation: boolean
}

export type BasketWork = {
    workItem: WorkItem // Response from ShowWorkTaskBasket
    estimatedWork?: WorkResponse // Response from ShowWorkEstimation
    calculatedWork?: CalculatedWork // Response from CalcualteWorkTaskBasket
    states: BasketWorkStates
    hasErrors?: boolean
}

// used only in the frontend
type BasketWorkStates = {
    isSelected?: boolean
    isExpanded?: boolean
    isEditorOpen?: boolean
    isBeingUpdated?: boolean
}

export type GetErpInformationBasketRequestPage = GetErpInformationBasketRequest & {
    pageIndex?: number
}

export type WorkTaskBasketCalculationInfo = {
    workTaskBasketCalculation: CalculateWorkTaskBasketResponse
    workTaskBasketCalculationLoading: boolean
    workTaskBasketCalculationWithError: boolean
}

export type SendArticlesPostMessagePart = {
    articleType: ArticleInfoType // 1: TecDoc, 2: Oe, 4: Wholesaler
    articleIdErp?: string // WholesalerArticleNumber / ArticleIdErp
    additionalDescription?: string
    articleNumber?: string // DataSupplier-, OeArticleNumber
    description?: string
    linkedItems?: Array<{
        additionalDescription?: string
        articleIdErp?: string // WholesalerArticleNumber / ArticleIdErp
        description?: string
    }>
    oeOrigin?: OE.OeArticleOrigin
    dataSupplier?: {
        id?: number // DataSupplierId (Catalog/TecDoc) or VehicleManufacturerId (Oe)
        name?: string // DataSupplierName (Catalog/TecDoc) or VehicleManufacturerName (Oe)
        dataPoolId: DataPool // 0:Topmotive, 1: TecDoc
        tmfId?: number // dataPoolId + id
    }
    productGroup?: {
        id?: number // ProductGroupId/ GenArt
        name?: string // ProductGroupName / GenArtBez
        dataPoolId?: DataPool // 0:Topmotive, 1: TecDoc
        tmfId?: number // dataPoolId + id
    }
    quantity: number
    vehicle?: {
        manufacturerId?: number // TecDoc manufacturer id
        modelId?: number // TecDoc model id
        typeId?: number // TecDoc type id
        dataPoolId?: DataPool // 0:Topmotive, 1: TecDoc
        tmfId?: number // dataPoolId + id
    }
}
